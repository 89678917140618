/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Terms from "views/Terms";
import Privacy from "views/Privacy";
import Main from "views/Main";

const routes = [
  {
    path: "/main",
    name: "Main",
    icon: "",
    component: Main,
    layout: "/info",
  },
  {
    path: "/terms",
    name: "Terms",
    icon: "",
    component: Terms,
    layout: "/info",
  },
  {
    path: "/privacy",
    name: "Privacy",
    icon: "",
    component: Privacy,
    layout: "/info",
  },
];

export default routes;
