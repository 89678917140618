/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

import { events } from "variables/general.js";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: events,
      alert: null,
    };
  }
  selectedEvent = (event) => {
    alert(event.title);
  };
  addNewEventAlert = (slotInfo) => {
  };
  addNewEvent = (e, slotInfo) => {
    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
    });
    this.setState({
      alert: null,
      events: newEvents,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto" md="10">
                <h2>Mouseware Designs</h2>
                <h5>
                    We are building a SaaS product for youth organizations (mainly, non-profit) to more accurately and 
                    efficiently manage and help their volunteers comply with state child protection laws. We are focused 
                    on working with organizations that have to comply with the laws of Pennsylvania Act 15 of 2015 and California AB-506, Youth service organizations: child abuse and neglect prevention.
                </h5>
                <h5>
                    Our product can manage the requirements of your organization. Contact us below for more information. 
                </h5>

                <a href="mailto:hello@act15pa.com"><h3>Contact Us</h3></a>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Main;
