/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

import { events } from "variables/general.js";

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: events,
      alert: null,
    };
  }
  selectedEvent = (event) => {
    alert(event.title);
  };
  addNewEventAlert = (slotInfo) => {
  };
  addNewEvent = (e, slotInfo) => {
    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
    });
    this.setState({
      alert: null,
      events: newEvents,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <h2>Privacy Policy</h2>
              <p>Last updated: March 5, 2022</p>
              <p>This document outlines Our policies on the collection, use and distribution of Your information when You use the Website. 
                This policy does not cover the use of information by Your Organization. By using the Website, You agree to the use of Your 
                information in accordance with this Privacy Policy.</p>

              <h3>Definitions</h3>
              <p>For the purposes of this document</p>
              <ul>
                <li><span style={{fontWeight: "bold"}}>Company</span> (“Mouseware”, “we”, “our”, or “us”) refers to Embassis Capital, Inc.</li>
                <li><span style={{fontWeight: "bold"}}>Cookies</span> are pieces of data stored locally on your device that are used by a website to retrieve specific information about you.</li>
                <li><span style={{fontWeight: "bold"}}>Personal Data</span> is any type of information that can identify an individual.</li>
                <li><span style={{fontWeight: "bold"}}>Organization</span> refers to an organization that utilizes Our Website for services.</li>
                <li><span style={{fontWeight: "bold"}}>Website</span> refers to Act 15 PA Clearances, located at www.act15pa.com, or any subdomains or aliases.</li>
                <li><span style={{fontWeight: "bold"}}>You</span> (“you” or “your”) refers to any individual accessing the Website or services provided on the Website.</li>
              </ul>
              
              <h3>Information We Collect</h3>
              <p>The service We provide on our Website does not collect any Personal Data from You, unless you or Your Organization provides it. 
                We may use aggregate information provided by You or Your Organization to improve Our Website in accordance with our Terms of Use.</p>
              <p>Act 15 PA Clearances is a Website that helps organizations comply with child protection laws, like the Pennsylvania Act 15 of 2015. 
                Information provided by You could contain Personal Data. </p>
              <p>The information You provide through Our Website is accessible to Your Organization. For example, documents You provide could be 
                reviewed for accuracy by individuals in Your Organization. Contact Your Organization for their Privacy Policy. </p>
              <p>Your Organization may provide Us with Your Personal Data so that We can enable access to restricted portions of Our Website. 
                This Personal Data could contain unique identifiers, names, email addresses, or date of birth. For example, we may use the email 
                address provided by Your Organization to send You an email to verify your identity. We do not share, disclose, or sell any of Your information. </p>
              <p>Your Organization may configure Our Website configured to send You automated electronic notifications. We will never send you an 
                unsolicited notification unless it related to the service provided by Our Website.</p>
              <p>We log individual web requests to Our Website for use in better understanding traffic and to diagnose technical issues. These logs 
                could include time of request, Your IP address, browser or device specific identifiers, or details on the request. </p>
                
              <h3>Retention of Your Personal Data</h3>
              <p>We will only retain Your Personal Data for as long as required to perform Our service to You, Your Organization, or to the extent 
                necessary to comply with our legal obligations (for example, document You upload on Our Website may be stored for as long as 
                required by Your Organizations policies or applicable laws).</p>
                
              <h3>Security of Your Personal Data</h3>
              <p>The security of Your Personal Data is extremely important to Us. We use industry standard encryption for keeping Your information 
                as secure as possible while in transit and at rest. While We strive to keep Your Personal Data secure, we cannot guarantee its 
                absolute security. If You feel that there may be an issue, please contact us immediately. </p>

              <h3>Service Area</h3>
              <p>Our Website is designed to provide a service to individuals located in the United States of America only. While we do not 
                explicitly restrict access to any portion of Our Website to any geographical region, we do not collect any Personal Data from 
                areas outside of the United States of America, unless Your Organization provided it to Us. All information stored by Us is stored 
                in the United States of America. </p>

              <h3>Third Party Services</h3>
              <p>Third-party services are used in providing functionality of Our Website. For information on how they protect Your information, 
                please review their Privacy Policy. </p>

              <h2>Cloudflare</h2>
              <p>We use Cloudflare to cache some information to help improve responsiveness and decrease load times on Our Website. </p>

              <h2>Postmark</h2>
              <p>We use Postmark to send electronic notifications from Our Website to You. We only provide the minimum amount of information 
                needed to send the notification to Postmark. This could include Your name, email address, or Personal Data as configured 
                by Your Organization.</p>

              <h3>Policy Changes</h3>
              <p>From time to time, We may update Our Privacy Policy. If we do so, we will inform You by posting the updated Privacy Policy 
                on this page of Our website. You are responsible for visiting Our Privacy Policy from time to time to be sure you are aware 
                of any updates to this policy. Changes made to Our Privacy Policy are effective at the time they are posted on this page.</p>

              <h3>Contact Us</h3>
              <p>If you have any questions about Our Privacy Policy, You can contact us by email at <a href="mailto:support@act15pa.com">support@act15pa.com</a>.</p>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Privacy;
